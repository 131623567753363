<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query
			:query="query"
			:update="data => data.missions"
			:variables="{ input: { monthsForward, monthsBackward } }"
		>
			<template #default="{ result: { loading, data } }">
				<template v-if="data">
					<template v-if="data.length === 0">
						<v-subheader class="title">Du har inga bokningar</v-subheader>
					</template>
					<template v-else>
						<a-next-mission-card
							v-if="upcomingMissionsExist(data)"
							:mission="nextMission(data)"
						/>

						<a-action-card v-bind="book" class="mt-2" />

						<template v-if="upcomingMissionsExist(data)">
							<div class="title-container">
								<v-subheader class="title">Kommande bokningar</v-subheader>
								<v-subheader class="subtitle-2">
									Visar bokningar till och med
									{{ monthsText(monthsForward) }}
								</v-subheader>
							</div>
							<a-bookings-card :missions="upcomingMissions(data)" />
						</template>

						<template v-if="previousMissions(data).length > 0">
							<div class="title-container">
								<v-subheader class="title">Historik</v-subheader>
								<v-subheader class="subtitle-2">
									Visar bokningar från och med
									{{ monthsText(-monthsBackward) }}
								</v-subheader>
							</div>
							<a-bookings-card :missions="previousMissions(data)" />
						</template>
					</template>
				</template>

				<template v-else>
					<a-loading :loading="loading" type="card-heading, list-item@3" />
					<a-loading
						:loading="loading"
						type="card-heading, list-item-three-line@3"
					/>
				</template>
			</template>
		</apollo-query>

		<apollo-query
			:query="fabQuery"
			:update="data => data.issueTypes"
			:variables="{ input: { view: 'bookings' } }"
		>
			<template #default="{ result: { data } }">
				<a-create-issue-fab v-if="data" :issue-types="data" />
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import spacetime from 'spacetime';
import AActionCard from '@/components/ActionCard';
import missionMixin from '@/mixins/missionMixin';
import { MISSIONS, ISSUE_CREATION_TYPES } from '@/graphql';
import ANextMissionCard from '@/components/NextMissionCard';
import ABookingsCard from '@/components/BookingsCard';
import ALoading from '@/components/Loading';
import ACreateIssueFab from '@/components/CreateIssueFab';

export default {
	components: {
		AActionCard,
		ANextMissionCard,
		ABookingsCard,
		ALoading,
		ACreateIssueFab,
	},
	mixins: [missionMixin],
	data: () => ({
		query: MISSIONS,
		fabQuery: ISSUE_CREATION_TYPES,
		now: spacetime.now('Europe/Stockholm'),
		book: {
			to: { name: 'book' },
			icon: 'mdi-plus',
			title: 'Bokningsförfrågan',
			text: 'Tryck här för att skicka en bokningsförfrågan',
			action: 'mdi-arrow-right',
		},
		monthsForward: 4,
		monthsBackward: 2,
	}),
	methods: {
		nextMission(missions) {
			const upcomingMissions = this.upcomingMissions(missions);
			if (!upcomingMissions || upcomingMissions.length === 0) {
				return null;
			}

			// Reduce to the nearest mission by start date and time.
			return upcomingMissions.reduce((a, b) => {
				const aDateTime = spacetime(a.employees[0].startDate).time(
					a.employees[0].startTime,
				);
				const bDateTime = spacetime(b.employees[0].startDate).time(
					b.employees[0].startTime,
				);

				return bDateTime.isBefore(aDateTime) ? b : a;
			});
		},
		upcomingMissionsExist(data) {
			const upcomingMissions = this.upcomingMissions(data);
			return upcomingMissions && upcomingMissions.length > 0;
		},
		upcomingMissions(missions) {
			return this.missionsOrderedByDateTime(
				missions.filter(mission =>
					this.$_missionMixin_isBefore(
						this.now,
						this.$_missionMixin_getStartDate(mission),
					),
				),
			);
		},
		previousMissions(missions) {
			return this.missionsOrderedByDateTime(
				missions.filter(mission =>
					this.$_missionMixin_isBefore(
						this.$_missionMixin_getEndDate(mission),
						this.now,
					),
				),
				true,
			);
		},
		missionsOrderedByDateTime(missions, reverse = false) {
			if (!missions || missions.length === 0) {
				return [];
			}

			return missions.slice(0).sort((a, b) => {
				const aDate = this.$_missionMixin_getStartDate(a);
				const bDate = this.$_missionMixin_getStartDate(b);
				if (this.$_missionMixin_isBefore(aDate, bDate)) {
					return reverse ? 1 : -1;
				}

				if (this.$_missionMixin_isBefore(bDate, aDate)) {
					return reverse ? -1 : 1;
				}

				return 0;
			});
		},
		monthsText: monthsFromNow =>
			spacetime
				.today()
				.add(monthsFromNow, 'month')
				.format('iso-short'),
	},
	metaInfo: {
		title: 'Bokningar',
	},
};
</script>

<style>
.title-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.title-container > .v-subheader {
	height: auto;
}
</style>
