<template>
	<v-card class="mb-2">
		<v-list three-line>
			<v-list-item
				v-for="(mission, index) in missions"
				:key="index"
				:to="{
					name: 'booking',
					params: { id: mission.id },
				}"
				three-line
			>
				<v-list-item-content>
					<v-list-item-title
						v-text="mission.services.map(service => service.name).join(', ')"
					/>
					<v-list-item-subtitle>
						{{ getMissionDate(index) }}
					</v-list-item-subtitle>
					<v-list-item-subtitle>
						{{ getMissionTime(index) }}
					</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-icon class="mb-auto mt-auto">
					<v-icon>mdi-arrow-right</v-icon>
				</v-list-item-icon>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import missionMixin from '@/mixins/missionMixin';

export default {
	mixins: [missionMixin],
	props: {
		missions: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		getMissionDate(index) {
			const mission = { ...this.missions[index] };
			return (
				this.$_missionMixin_missionDayName(mission) +
				'en den ' +
				this.$_missionMixin_missionDate(mission) +
				' ' +
				this.$_missionMixin_missionMonth(mission) +
				' ' +
				this.$_missionMixin_missionYear(mission)
			);
		},
		getMissionTime(index) {
			const mission = { ...this.missions[index] };
			return this.$_missionMixin_missionTime(mission);
		},
	},
};
</script>

<style></style>
